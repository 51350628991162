/* eslint-disable react/no-array-index-key */
import React from 'react';
import {
  Alert, Avatar, Box, Container, Link, ListItemAvatar,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const url = 'https://vm.vistar.ru';

const list1 = [
  'Зайти на сайт',
  'Зарегистрироваться через VK.',
  'Подтвердить аккаунт переходом по ссылке в письме, установить пароль по ссылке из письма.',
  'Заполнить карточку компании/пользователя.',
  'Изменить тарифный план при необходимости в меню «Финансы». ',
  'Создать подразделение в компании  и транспортное средство/подвижный объект.',
  'Установить мобильное приложение «ВИСТАР. Водитель».',
  'Запустить и зарегистрироваться в мобильном приложении с тем же логином и паролем.',
  'Подключиться к OBD (адаптер ELM 327) через Bluetooth в меню Настройки/OBD (опционально).',
  'Начать поездку, нажать кнопку «Старт» в мобильном приложении.',
  'Просмотреть историю передвижения, топливную эффективность и места заправок  транспортного средства в веб-приложении.',
];

const sections = [
  {
    title: 'Шаги для подключения трекера для мониторинга автомобиля или другого подвижного объекта:',
    items: [
      'Создать новый автомобиль или другой подвижный объект.',
      'На вкладке Оборудование указать название производителя и модель устройства, выбрать протокол из списка поддерживаемых, заполнить код идентификации устройства (IMEI).',
      'Нажать «Сохранить».',
      'Выполнить поездку с подключенным трекером.',
    ]
  },
  {
    title: 'Шаги для использования функций логистики (кратчайший путь по ограниченной территории с препятствиями, доставка грузов и пассажиров по городу)(доступно на тарифном плане «Бизнес»):',
    items: [
      'Открыть форму Логистика, выбрать тип задачи.',
      'Загрузить исходные данные в зависимости от типа задачи. Ориентируйтесь на подсказки на форме.',
      'Просмотреть исходные данные в графическом представлении. При необходимости дополнить информацию путем ввода текстовых данных или в виде геометрических объектов на карте.',
      'Нажать кнопку «Построить путь».',
      'Просмотреть полученный вариант.',
      'Если предлагаемый маршрут решает исходную задачу, нажать кнопку «Создать маршрут для ТС» и запланировать дату начала. Выбор автомобиля или корректировка времени начала движения или других параметров маршрута могут быть выполнены позже при необходимости.',
      'На мобильном приложении выбрать автомобиль, открыть список маршрутов и перейти на маршрут, выбранный для движения.',
    ]
  },
  {
    title: 'Шаги для использования функций мониторинга пассажирских перевозок и информирования пассажиров:',
    items: [
      'Добавить список пассажиров из Excel файла в список пассажиров для компании. Первая строка должна содержать названия следующих столбцов в любом порядке: Пассажир, Класс, Комментарии, Логин, Пароль, Родитель, Пароль родителя. Строки содержат информацию о конкретных пассажирах.',
      'Создать маршрут передвижения с графиком и выбранным ТС.',
      'Добавить к маршруту пассажиров на форме редактирования маршрута.',
      'Установить мобильное приложение «Школьный автобус» для пассажира.',
      'Зарегистрироваться в приложении с использованием имени и пароля, указанных в Excel.',
      'Просмотреть доступные маршруты и найти маршрут с посадочным местом для поездки. ',
      'В день поездки включить мобильное приложение пассажира, открыть маршрут с местом для поездки, перейти на просмотр карты. На карте будет показано приближающееся транспортное средство (автобус), следующий по маршруту.',
      'При посадке в автобус пассажиру необходимо предъявить QR-код из приложения (опционально).',
      'Для водителя ил сопровождающего необходимо установить мобильное приложение водителя. ',
      'Зарегистрироваться в приложении с использованием имени и пароля пользователя компании (создать нового при необходимости).',
      'Для сопровождающего отключить передачу данных на сервер от датчиков в меню «Настройка».',
      'Выбрать маршрут из списка, проверить список пассажиров, одобрить заявки на места по маршруту или отказать в получении места.',
      'В день поездки запустить приложение, при начале движения по маршруту нажать кнопку «Старт» (только водителю).',
      'Выбрать маршрут из списка и перейти на просмотр карты. На карте будут показаны ожидающие посадки пассажиры. При посадке пассажира нажать кнопку «В салоне» напротив имени и фамилии пассажира или отсканировать мобильным приложением QR код пассажира. При высадке пассажира произвести аналогичные действия.',
    ]
  }
];

// Страница справка по системе
function QuickStartView() {
  return (
    <Container
      component="main"
      maxWidth="lg"
      sx={{
        paddingTop: '40px',
        paddingBottom: '40px',
      }}
    >
      <Typography variant="h5" align="center" gutterBottom sx={{ marginBottom: '40px' }}>
        Добро пожаловать в систему мониторинга ВИСТАР-М!
      </Typography>
      <Alert severity="info" sx={{ marginBottom: '40px' }}>
        Краткая инструкция по использованию приложения.
      </Alert>
      <Box sx={{ marginBottom: '20px' }}>
        <Typography variant="h6">
          Шаги по настройке системы для мониторинга местоположения автомобиля:
        </Typography>
        <List>
          {list1.map((item, itemIndex) => (
            <ListItem key={itemIndex} sx={{ alignItems: 'self-start' }}>
              <ListItemAvatar>
                <Avatar
                  sx={{ bgcolor: '#1769aa', fontSize: '1em' }}
                >
                  {itemIndex + 1}
                </Avatar>
              </ListItemAvatar>
              {itemIndex === 0
                ? (
                  <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '10px' }}>
                    <ListItemText primary={item} key={itemIndex} />
                    <Link href={url} target="_blank" rel="noopener">
                      {url}
                    </Link>
                  </Box>
                )
                : (
                  <ListItemText primary={item} key={itemIndex} />
                )}
            </ListItem>
          ))}
        </List>
      </Box>
      {sections.map((section, index) => (
        <Box key={index} sx={{ marginBottom: '20px' }}>
          <Typography variant="h6">{section.title}</Typography>
          <List>
            {section.items.map((item, itemIndex) => (
              <ListItem key={itemIndex} sx={{ alignItems: 'self-start' }}>
                <ListItemAvatar>
                  <Avatar
                    sx={{ bgcolor: '#1769aa', fontSize: '1em' }}
                  >
                    {itemIndex + 1}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item} key={itemIndex} />
              </ListItem>
            ))}
          </List>
        </Box>
      ))}
    </Container>
  );
}

export default QuickStartView;
