import React, {
  lazy, Suspense, useCallback, useEffect, useMemo, useState
} from "react";
import { number } from "prop-types";
import toast from "react-hot-toast";
import ExportDataBySensorsButton from "./button";
import ExportDataBySensorsWindow from "./window";

const ExportDataBySensorsForm = lazy(() => import('./ExportDataBySensorsForm/ExportDataBySensorsForm'));

function ExportDataBySensors({ geoZoneId }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = useCallback(() => setIsOpen(false), [setIsOpen]);
  const handleOpen = useCallback(() => setIsOpen(true), [setIsOpen]);

  const form = useMemo(() => (
    geoZoneId ? (
      <ExportDataBySensorsForm
        onClose={handleClose}
        geoZoneId={geoZoneId}
      />
    ) : null
  ), [handleClose, geoZoneId]);

  useEffect(() => {
    if (!geoZoneId && isOpen) {
      toast.error('Геозона была утеряна, начните процесс заново.');

      setIsOpen(false);
    }
  }, [isOpen, geoZoneId]);

  return (
    <>
      <Suspense>
        <ExportDataBySensorsWindow
          isOpen={isOpen}
          onClose={handleClose}
        >
          {form}
        </ExportDataBySensorsWindow>
      </Suspense>

      <ExportDataBySensorsButton
        disabled={!geoZoneId}
        onClick={handleOpen}
      />
    </>
  );
}

ExportDataBySensors.propTypes = {
  geoZoneId: number.isRequired,
};

export default React.memo(ExportDataBySensors);
