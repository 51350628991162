import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import React from "react";
import { bool, func, string } from "prop-types";

function ExportDataBySensorsButton({ tooltipTitle = "Экспорт данных", ...iconButtonProps }) {
  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        {...iconButtonProps}
        aria-haspopup="true"
        color="primary"
      >
        <FileDownloadIcon />
      </IconButton>
    </Tooltip>
  );
}

ExportDataBySensorsButton.propTypes = {
  disabled: bool.isRequired,
  onClick: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  tooltipTitle: string,
};

export default React.memo(ExportDataBySensorsButton);
