import * as React from 'react';
import Button from '@mui/material/Button';
import { func, string, bool } from 'prop-types';
import DialogActions from '@mui/material/DialogActions';
import Loader from "./Loader";

function FormDialogButtons(props) {
  const {
    onClose,
    onClick,
    saveTitle = 'Сохранить',
    cancelTitle = 'Назад',
    loading = false
  } = props;

  return (
    <DialogActions>
      {loading && (<Loader />)}

      <Button onClick={onClose}>{cancelTitle}</Button>
      <Button
        onClick={onClick}
      >
        {saveTitle}
      </Button>
    </DialogActions>
  );
}

FormDialogButtons.propTypes = {
  onClose: func.isRequired,
  onClick: func.isRequired,
  // eslint-disable-next-line react/require-default-props
  saveTitle: string,
  // eslint-disable-next-line react/require-default-props
  cancelTitle: string,
  // eslint-disable-next-line react/require-default-props
  loading: bool,
};

export default React.memo(FormDialogButtons);
