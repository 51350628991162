// eslint-disable-next-line import/no-cycle,boundaries/element-types
import { refreshTokenModule } from "../../entities/baseQueryWithReAuth/baseQueryWithReAuth";
import handleError from "./handleError";

// eslint-disable-next-line @typescript-eslint/no-require-imports
const axios = require('axios');

const instance = axios.create();

instance.interceptors.request.use((config) => {
  // eslint-disable-next-line no-undef
  const accessToken = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');

  // Если токен есть, добавляем его в заголовок запроса
  if (accessToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${accessToken}`;
  }

  return config;
});

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    // Если ошибка связана с истекшим токеном (код ошибки 401),
    // выполняем логику обновления токена и повторяем запрос
    if (error?.response?.status === 401) {
      await refreshTokenModule();
    }

    return Promise.reject(handleError(error));
  }
);

export default instance;
