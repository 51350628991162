// Функци From DTO берет сырые данные с бека и превращает их в данные котоые хочет видеть фронт
function sensorsValuesTypesResourceFromDtoService(dto, meta) {
  const { status } = meta.response;

  const data = dto?.slice().sort((a, b) => a.name.localeCompare(b.name));

  return { data, status };
}

export default sensorsValuesTypesResourceFromDtoService;
