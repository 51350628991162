import {
  Box, Button, CircularProgress, Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import PropTypes from "prop-types";
import style from './VehicleFuelEfficiencyPanel.module.css';
import LineChart, {
  dataType,
  filterCoefficientType
} from "../../../widgets/vehicles/VehicleFuelEfficiencyWidget/Charts/LineChart/LineChart";
import { vehicleFuelEfficiencyType } from "../../../entities/vehicles/vehicleFuelEfficiency/redux/vehicleFuelEfficiency.slice";

const constants = {
  navigationDate: {
    stepLeft: true,
    stepRight: false
  }
};
// Получает дату до которой считать период
const getDateFinish = (type, date) => {
  const step = 1;
  switch (type) {
    case dataType.day: {
      const newDate = new Date(date);
      return new Date(newDate.setDate(newDate.getDate() - step));
    }
    case dataType.month:
    {
      const newDate = new Date(date);
      return new Date(newDate.setMonth(newDate.getMonth() - step));
    }
    case dataType.year:
    {
      const newDate = new Date(date);
      return new Date(newDate.setFullYear(newDate.getFullYear() - step));
    }
    default:
      return new Date();
  }
};
// Определение какие данные отправлять на бек в зависимости от выбранной кнопки
const switchAvgMode = (data) => {
  switch (data) {
    case dataType.day:
      return "AVG_MIN10";
    case dataType.month:
      return "AVG_HOUR";
    case dataType.year:
      return "AVG_DAY";
    default:
      return "AVG_MIN10";
  }
};
// Определение какие данные отправлять на бек в зависимости от выбранной кнопки
const switchFilterCoefficient = (data) => {
  switch (data) {
    case filterCoefficientType.format1:
      return {};
    case filterCoefficientType.format2:
      return {
        "anglep.lessThan": 3,
        "anglep.greaterThanOrEqual": 0
      };
    case filterCoefficientType.format3:
      return {
        "anglep.lessThan": 7,
        "anglep.greaterThanOrEqual": 4
      };
    case filterCoefficientType.format4:
      return {
        "anglep.greaterThanOrEqual": 7,
      };
    default:
      return {};
  }
};
// Навигация, + 1 день/месяц/год  или -1 день/месяц/год
const navigation = (typeClick, dataTypeState, setDateStart) => {
  const step = 1;
  switch (dataTypeState) {
    case dataType.day: {
      setDateStart(
        (current) => new Date(current.setDate(
          typeClick ? current.getDate() - step : current.getDate() + step
        ))
      );
      return typeClick;
    }
    case dataType.month: {
      setDateStart(
        (current) => new Date(current.setMonth(
          typeClick ? current.getMonth() - step : current.getMonth() + step
        ))
      );
      return typeClick;
    }
    case dataType.year: {
      setDateStart(
        (current) => new Date(current.setFullYear(
          typeClick ? current.getFullYear() - step : current.getFullYear() + step
        ))
      );
      return typeClick;
    }
    default:
      return new Date();
  }
};

// Панель транспортного средства с пагинацией даты (год месяц день)
function VehicleFuelEfficiencyPanel({
  data, getVehicleFuelEfficiencyParams, id
}) {
  // Тип показа графика День/Месяц/Год
  const [dataTypeState, setDataTypeState] = useState(dataType.day);
  // формат показа графика по фильтру коэффициент
  const [dataFilterCoefficientState,
    setDataFilterCoefficientState] = useState(filterCoefficientType.format1);

  // Дата от
  const [dateStart, setDateStart] = useState(new Date(new Date().setHours(0, 0, 0, 0)));

  useEffect(() => {
    let params = {
      "startDate.lessThan": dateStart.toISOString(),
      "startDate.greaterThanOrEqual": getDateFinish(dataTypeState, dateStart).toISOString(),
      "vehicleId.equals": id,
      avgMode: switchAvgMode(dataTypeState),
    };
    if (dataFilterCoefficientState !== filterCoefficientType.format1) {
      params = Object.assign(params, switchFilterCoefficient(dataFilterCoefficientState));
    }
    if (getVehicleFuelEfficiencyParams) getVehicleFuelEfficiencyParams(params);
  }, [dataTypeState, dateStart, id, dataFilterCoefficientState]);

  return (
    <Box className={style.vehicleFuelEfficiencyPanel}>
      <Box sx={{
        display: 'flex',
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        flexWrap: "wrap"
      }}
      >
        <Box sx={{
          display: 'flex',
        }}
        >
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataTypeState === dataType.day ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataTypeState(dataType.day)}
            >
              ДЕНЬ
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataTypeState === dataType.month ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataTypeState(dataType.month)}
            >
              МЕСЯЦ
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataTypeState === dataType.year ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataTypeState(dataType.year)}
            >
              ГОД
            </Button>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
        }}
        >
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataFilterCoefficientState === filterCoefficientType.format1 ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataFilterCoefficientState(filterCoefficientType.format1)}
            >
              ОБЩ
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataFilterCoefficientState === filterCoefficientType.format2 ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataFilterCoefficientState(filterCoefficientType.format2)}
            >
              0-3%
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataFilterCoefficientState === filterCoefficientType.format3 ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataFilterCoefficientState(filterCoefficientType.format3)}
            >
              4-7%
            </Button>
          </Box>
          <Box sx={{ m: 1 }}>
            <Button
              variant={dataFilterCoefficientState === filterCoefficientType.format4 ? "contained" : "outlined"}
              color="primary"
              onClick={() => setDataFilterCoefficientState(filterCoefficientType.format4)}
            >
              {`>`}
              {' '}
              7%
            </Button>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          alignItems: "center",
          justifyContent: "space-between",
          maxWidth: "400px"
        }}
        >
          <Box
            sx={{
              display: 'flex',
            }}
            onClick={() => navigation(
              constants.navigationDate.stepLeft,
              dataTypeState,
              setDateStart
            )}
          >
            <KeyboardArrowLeftIcon
              sx={{
                cursor: "pointer"
              }}
            />
          </Box>
          <Typography
            sx={{
              p: 1,
            }}
          >
            {`${getDateFinish(dataTypeState, dateStart).toLocaleDateString()} - ${dateStart.toLocaleDateString()}`}
          </Typography>
          <Box
            sx={{
              display: 'flex',
            }}
            onClick={() => navigation(
              constants.navigationDate.stepRight,
              dataTypeState,
              setDateStart
            )}
          >
            <KeyboardArrowRightIcon sx={{
              cursor: "pointer"
            }}
            />
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          {data.isLoading ? (
            <Box
              sx={{
                display: 'flex',
                height: "444px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <CircularProgress />
            </Box>
          ) : data?.data?.data && (
          <LineChart
            dataTypeValue={dataTypeState}
            width="100%"
            height={200}
            data={data.data.data}
          />
          )}
        </Box>
      </Box>
    </Box>
  );
}

VehicleFuelEfficiencyPanel.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.arrayOf(PropTypes.shape(vehicleFuelEfficiencyType)),
    }),
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    isSuccess: PropTypes.bool,
    status: PropTypes.string,
  }),
  getVehicleFuelEfficiencyParams: PropTypes.func,
  id: PropTypes.number
};

VehicleFuelEfficiencyPanel.defaultProps = {
  id: null,
  data: null,
  getVehicleFuelEfficiencyParams: () => {},
};

export default VehicleFuelEfficiencyPanel;
