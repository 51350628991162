import {
  Box, TextField, Tooltip
} from '@mui/material';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import React from 'react';
import PropTypes from 'prop-types';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import IconButtonBadge from '../../shared/ui/IconButtonBadge/IconButtonBadge';
import DeleteButton from '../../shared/ui/DeleteButton/DeleteButton';
import style from './GeoZonesToolbarWidget.module.css';
import ExportDataBySensors from "./components/components/ExportDataBySensors/ExportDataBySensors";

const DRAW_FREEHAND_KEY = 'Shift + Нажатая левая кнопка мыши';
const DRAW_FREEHAND_HINT = 'Рисовать границы непрерывно(от руки)';

const DELETE_POINT_KEY = 'Alt + Левая кнопка мыши';
const DELETE_POINT_HINT = 'Удалить точку из контура геозоны';

// Отображает поле редактирования имени геозоны, кнопки создания/удаления геозоны
function GeoZonesToolbarWidget(props) {
  const {
    geoZone, nameGeoZone, onClickDelete, errors, setErrors, setIsChanged,
    setNameGeoZone, onClickCreate, isCreateGeoZone
  } = props;

  return (
    <Box className={style.geoZonesToolbarWidget}>
      <Box className={style.nameGeoZone}>
        {geoZone?.name || isCreateGeoZone
          ? (
            <TextField
              fullWidth
              size="small"
              id="nameGeoZone"
              label="Название геозоны"
              value={nameGeoZone}
              onChange={(e) => {
                if (errors.name) {
                  setErrors((prev) => ({
                    ...prev,
                    name: false,
                  }));
                }
                setIsChanged(true);
                setNameGeoZone(e.target.value);
              }}
              // onFocus={onFocusName}
              helperText={errors.name ? 'Заполните поле' : ''}
              error={errors.name}
              required
              sx={{ width: "100%" }}
              onBlur={() => {
                if (!nameGeoZone) {
                  setErrors((prev) => ({
                    ...prev,
                    name: true,
                  }));
                }
              }}
            />
          )
          : null}
      </Box>
      <Box className={style.controlGeoZoneButtons}>
        <IconButtonBadge
          disabled={isCreateGeoZone}
          title="Добавить геозону"
          onClick={() => onClickCreate()}
        >
          <InsertPhotoIcon />
        </IconButtonBadge>
        <DeleteButton
          disabled={!geoZone}
          onDelete={() => onClickDelete()}
          name="геозону"
        />
        <ExportDataBySensors geoZoneId={geoZone?.geozoneId} />
        <Tooltip
          title={(
            <>
              <strong>{DRAW_FREEHAND_KEY}</strong>
              {' '}
              —
              {' '}
              {DRAW_FREEHAND_HINT}
              <br />
              <br />
              <strong>{DELETE_POINT_KEY}</strong>
              {' '}
              —
              {' '}
              {DELETE_POINT_HINT}
            </>
          )}
        >
          <HelpOutlineOutlinedIcon color="primary" />
        </Tooltip>
      </Box>
    </Box>
  );
}

GeoZonesToolbarWidget.propTypes = {
  geoZone: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.array,
  ])),
  nameGeoZone: PropTypes.string.isRequired,
  onClickCreate: PropTypes.func.isRequired,
  isCreateGeoZone: PropTypes.bool.isRequired,
  setNameGeoZone: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    name: PropTypes.bool.isRequired,
    polygon: PropTypes.bool.isRequired,
  }).isRequired,
  setErrors: PropTypes.func.isRequired,
  setIsChanged: PropTypes.func.isRequired,
};

GeoZonesToolbarWidget.defaultProps = {
  geoZone: null,
};

export default GeoZonesToolbarWidget;
