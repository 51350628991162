import * as React from 'react';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormDialogButtons from "./FormDialogButtons";

function FormDialog(props) {
  const {
    open,
    handleClose,
    onSave,
    label,
    helperText,
    dialogContent,
    title,
    disableTextField,
    saveTitle,
    cancelTitle,
    hideButtons,
    loading,
  } = props;
  const [text, setText] = React.useState('');
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {dialogContent}
        {!disableTextField
          ? (
            <TextField
              autoFocus
              margin="dense"
              id="textField"
              name="textField"
              label={label}
              value={text}
              onChange={(e) => setText(e.target.value)}
              fullWidth
              variant="standard"
              helperText={helperText}
            />
          )
          : null}
      </DialogContent>
      {!hideButtons && (
      <FormDialogButtons
        cancelTitle={cancelTitle}
        saveTitle={saveTitle}
        onClose={handleClose}
        loading={loading}
        onClick={() => {
          handleClose();
          onSave(text);
          setText('');
        }}
      />
      )}
    </Dialog>
  );
}

FormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string.isRequired,
  dialogContent: PropTypes.element,
  title: PropTypes.string.isRequired,
  saveTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  disableTextField: PropTypes.bool,
  hideButtons: PropTypes.bool,
  loading: PropTypes.bool,
};

FormDialog.defaultProps = {
  dialogContent: null,
  disableTextField: false,
  saveTitle: 'Сохранить',
  cancelTitle: 'Назад',
  hideButtons: false,
  loading: false
};

export default React.memo(FormDialog);
