import {
  Alert,
  Box, Breadcrumbs, Container, Typography, Link,
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useMemo, useState
} from 'react';
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';
import CompanyAlertsPanel from '../../../panels/alerts/CompanyAlertsPanel/CompanyAlertsPanel';
import CompanyInfoPanel from '../../../panels/companies/CompanyInfoPanel/CompanyInfoPanel';
import CompanyTreePanel from '../../../panels/companies/CompanyTreePanel/CompanyTreePanel';
import { getNode, getUrlForNode } from '../../../widgets/companies/CompanyTreeWidget';
import CompanyRoutesPanel from '../../../panels/vehicles/CompanyRoutesPanel/CompanyRoutesPanel';
import useAuth from '../../../shared/lib/hooks/useAuth';
import {
  getAppUsers, getCompanies, deleteCompany, deleteDivision,
  deleteVehicle,
  getCompany,
  deleteAppUser
} from '../../../shared/api/api';
import { NODES } from '../../../entities/tree/config';
import CompanyGeoZonesPanel from '../../../panels/companies/CompanyGeoZonesPanel.tsx';
import CompanyMapPanel from '../../../panels/companies/CompanyMapPanel/CompanyMapPanel';
import VehicleFuelEfficiencyPanel
  from "../../../panels/vehicles/VehicleFuelEfficiencyPanel/VehicleFuelEfficiencyPanel";
import VehicleFuelEfficiencyBll
  from "../../../entities/vehicles/vehicleFuelEfficiency/components/VehicleFuelEfficiencyBll/VehicleFuelEfficiencyBll";
import IconTree from '../../../shared/ui/IconTree/IconTree';
import VehicleMapPanel from '../../../panels/vehicles/VehicleMapPanel/VehicleMapPanel';
import EquipmentBll from "../../../entities/equipments/EquipmentBll/EquipmentBll";
import EquipmentPanel from "../../../panels/equipments/Equipment/EquipmentPanel";
import VehicleGroupPanel from '../../../panels/vehicles/VehicleGroupPanel/VehicleGroupPanel';
import StatePanel from "../../../panels/vehicles/State/StatePanel";
import CompanyPlatoonGroupPanel from '../../../panels/companies/CompanyPlatoonGroupPanel/CompanyPlatoonGroupPanel';
import VehicleBll from "../../../entities/vehicles/vehicle/VehicleBll/VehicleBll";
import VehicleMapPanelBll from "../../../entities/vehicles/VehicleMapPanelBll/VehicleMapPanelBll";
import AlertsSettingsResourceBll
  from "../../../entities/alerts/alertsSettingsResource/AlertsSettingsResourceBll/AlertsSettingsResourceBll";
import { tabsRoutes } from '../../../entities/companies/companyTabsPanels/redux/companyTabsPanels.slice';
import reducerPath from '../../../app/reducerPath';
import CompanyTabsPanels from '../../../entities/companies/companyTabsPanels/ui/CompanyTabsPanels';
import CompanyFormWidget from "../../../widgets/companies/CompanyFormWidget";
import TabsPanels from "../../../shared/ui/TabsPanels/TabsPanels";
import DivisionAccessBll from "../../../panels/divisions/DivisionAccessPanel/DivisionAccessBll";
import DivisionAccessPanel from "../../../panels/divisions/DivisionAccessPanel/DivisionAccessPanel";
import CompanyPassengersPanel from '../../../panels/passengers/CompanyPassengersPanel/CompanyPassengersPanel';
import NoData from '../../../shared/ui/NoData/NoData';
import Loading from '../../../shared/ui/Loading/Loading';
import CompanyLogisticsTablePanel from "../../../panels/companies/CompanyLogisticsTablePanel/CompanyLogisticsTablePanel";
import CompanyReportsPanel from '../../../panels/companies/CompanyReportsPanel/CompanyReportsPanel';
// eslint-disable-next-line
import PrivateRoute from '../../../app/providers/PrivateRoute';
import { useGetDivisionsResourceQuery } from '../../../entities/divisions/divisionsResource/redux/divisionsResource.api';
import ReportingPanel from '../../../panels/vehicles/ReportingPanel/ReportingPanel';
import VehicleReportingBll from '../../../entities/vehicles/vehicle/VehicleReportingBll/VehicleReportingBll';

const findNode = (key, nodes) => {
  const queue = [...nodes];
  while (queue.length > 0) {
    const node = queue.shift();
    if (node.key === key) {
      return node;
    } if (node.children && node.children.length > 0) {
      queue.push(...node.children);
    }
  }
  return null;
};

// Возвращает массив открытх узлов
export const getOpenNodes = (tree, kurKey) => {
  const keyParts = kurKey.split('-');
  let index = 0;
  const nodes = [];
  const loop = (data, key) => {
    for (let i = 0; i < data.length; i += 1) {
      if (key === data[i].key) {
        nodes.push({ ...data[i] });
        if (data[i].key !== kurKey) {
          if (data[i].children) {
            index += 1;
            loop(data[i].children, `${key}-${keyParts[index]}`);
          }
        }
        return;
      }
    }
  };
  loop(tree, keyParts[index]);
  return nodes;
};

const minTreeWidth = 290;
const maxTreeWidth = 500;

function CompanyView() {
  const [treeData, setTreeData] = useState([]);
  const [selectedNodeData, setSelectedNodeData] = useState(null);
  const [editedNodeData, setEditedNodeData] = useState(null);
  const [companies, setCompanies] = useState(null);
  const [treeSelectedKeys, setTreeSelectedKeys] = useState([]);
  const navigate = useNavigate();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [isCreateCompany, setIsCreateCompany] = useState(false);
  const [isErr, setIsErr] = useState(false);

  const { user, isAdmin, isCompanyAdmin } = useAuth();

  const { data: dataDivisionsResource } = useGetDivisionsResourceQuery(
    {
      params: {
        "companyId.equals": user?.company?.companyId
      }
    },
    { skip: !user?.company?.companyId }
  );

  // Создание узла
  const createNode = (newDataNode, node) => {
    // Формируем ключ для нового узла
    const newKey = (node?.children?.length || node?.length)
      ? Math.max(...(node.children || node).map((item) => {
        const index = item.key.lastIndexOf('-');
        return Number(item.key.substring(index + 1));
      })) + 1
      : 0;
    // Новый узел
    const newNode = {
      data: { ...newDataNode },
      title: newDataNode.name,
      key: node.key ? `${node.key}-${newKey}` : `${newKey}`,
      nodeType: newDataNode.nodeType,
      icon: <IconTree icon={NODES[newDataNode.nodeType].ICON} />,
      isLeaf: (newDataNode.nodeType !== NODES.COMPANY.TYPE
        && newDataNode.nodeType !== NODES.DIVISION.TYPE),
    };
    return newNode;
  };

  const valueStore = useSelector((state) => state[`${reducerPath.companyTabsPanelState}/counter`]);

  const toogleSelectedNode = useCallback((selectedKeys) => {
    const openNodes = getOpenNodes(treeData, selectedKeys[0] || '');
    const url = getUrlForNode(openNodes, valueStore);
    const node = openNodes[openNodes.length - 1];
    navigate(`${selectedKeys.length ? url : '/companies'}`);
    setTreeSelectedKeys(selectedKeys);
    setSelectedNodeData(node ? { ...node.data, key: node.key } : null);
  }, [valueStore, navigate, treeData]);

  const deleteNode = () => {
    const newTreeData = [...treeData];
    const nodeConnectorIndex = selectedNodeData.key.lastIndexOf('-');

    // Из родительского узла удаляем текущий по индексу
    const parentKey = selectedNodeData.key.slice(0, nodeConnectorIndex);
    const parentNode = nodeConnectorIndex > -1
      ? getNode(newTreeData, parentKey)
      : { children: newTreeData };
    const deletedNodeIndex = parentNode.children.findIndex((item) => (
      item.key === selectedNodeData.key
    ));
    parentNode.children.splice(deletedNodeIndex, 1);

    // Переключаем выбранный узел на предыдущий, либо родительский, если он есть
    const newKey = parentNode.children.length
      ? parentNode.children[Math.max(0, deletedNodeIndex - 1)].key
      : parentKey;
    toogleSelectedNode(newKey ? [`${newKey}`] : []);

    // Обновляем дерево
    setTreeData(newTreeData);
  };

  const remove = () => {
    let deleteReq = null;
    switch (selectedNodeData.nodeType) {
      case NODES.COMPANY.TYPE:
        deleteReq = deleteCompany(selectedNodeData.id);
        break;
      case NODES.DIVISION.TYPE:
        deleteReq = deleteDivision(selectedNodeData.id);
        break;
      case NODES.VEHICLE.TYPE:
        deleteReq = deleteVehicle(selectedNodeData.id);
        break;
      case NODES.USER.TYPE:
        deleteReq = deleteAppUser(selectedNodeData.id);
        break;
      default:
        break;
    }
    deleteReq?.then(() => {
      deleteNode();
    });
  };

  const [expandedKeys, setExpandedKeys] = useState([]);

  const [expandedNode, setExpandedNode] = useState(null);

  // обновить массив раскрытых узлов
  const updateOpenNodes = (curNode, newNodeId) => {
    setExpandedKeys((prev) => [...prev, curNode.key]);
    setExpandedNode({ key: curNode.key, newNodeId });
  };

  // раскрытие узла при добавлении в дерево, если узел закрыт
  useEffect(() => {
    if (expandedNode && treeData.length) {
      const newNode = findNode(expandedNode.key, treeData)?.children
        ?.find((child) => child?.data?.id === expandedNode.newNodeId);

      if (newNode) {
        toogleSelectedNode([newNode.key]);
        setExpandedNode(null);
      }
    }
  }, [treeData, expandedNode, toogleSelectedNode]);

  // Обновление дерева
  const updateTree = (key, newDataNode, isNewNode) => {
    let curNode = null;
    if (treeSelectedKeys.length) {
      curNode = getNode(treeData, treeSelectedKeys[0]);
    }

    if (!isNewNode) {
      curNode.title = newDataNode.name;
      curNode.data = newDataNode;
      setTreeData([...treeData]);
      return;
    }
    const index = treeSelectedKeys[0].lastIndexOf('-');
    let keyForAdd = treeSelectedKeys[0];
    let isAddInParent = false;
    if (newDataNode.nodeType === curNode?.nodeType
      && newDataNode.nodeType !== NODES.DIVISION.TYPE) {
      keyForAdd = treeSelectedKeys[0].substring(0, index);
      curNode = getNode(treeData, keyForAdd);
      isAddInParent = true;
    }

    // Если добавлем потомка в узел, а узел закрыт, нужно сначала раскрыть узел
    if (!isAddInParent && !curNode.children) {
      updateOpenNodes(curNode, newDataNode.id);
      return;
    }
    const isExpandedKey = expandedKeys.indexOf(curNode.key) > -1;
    if (!isExpandedKey) {
      setExpandedKeys((prev) => [...prev, curNode.key]);
    }
    const isLeaf = newDataNode.nodeType === NODES.VEHICLE.TYPE
      || newDataNode.nodeType === NODES.USER.TYPE;

    const lastNodeKey = curNode.children[curNode.children.length - 1]?.key;
    // Номер нового узла в key (key = ...-1-0-1-{subKey})
    const subKey = lastNodeKey
      ? Number(lastNodeKey.substring(lastNodeKey.lastIndexOf('-') + 1)) + 1
      : 0;

    const newNode = {
      data: { ...newDataNode },
      title: newDataNode.name,
      key: curNode.key ? `${keyForAdd}-${subKey}` : `${subKey}`,
      nodeType: newDataNode.nodeType,
      icon: <IconTree icon={NODES[newDataNode.nodeType].ICON} />,
      isLeaf
    };

    if (treeSelectedKeys.length) {
      curNode.children = [...curNode.children, newNode];
      setTreeData([...treeData]);
    } else {
      setTreeData([...treeData, { ...newNode }]);
    }
    toogleSelectedNode([newNode.key]);
  };

  const updateTreeForCompany = (key, newDataNode, isNewNode) => {
    const newTreeData = [...treeData];
    if (isNewNode) {
      const child = createNode(newDataNode, newTreeData);
      newTreeData.push(child);
      // setTreeSelectedKeys([child.key]);
    } else {
      const node = getNode(newTreeData, key);
      node.title = newDataNode.name;
      node.data = newDataNode;
    }
    // Обновляем состояние дерева
    setTreeData(newTreeData);
    setEditedNodeData(null);
  };

  const add = (nodeType) => {
    let data = {};
    let parent;
    switch (nodeType) {
      case NODES.COMPANY.TYPE:
        setEditedNodeData({ nodeType });
        break;
      case NODES.DIVISION.TYPE:
        parent = selectedNodeData.nodeType === NODES.DIVISION.TYPE ? selectedNodeData
          : (selectedNodeData.parent || selectedNodeData.division);
        data = {
          nodeType,
          company: { ...(selectedNodeData.company || { companyId: selectedNodeData.companyId }) },
          parent: parent ? { ...parent } : null,
          key: selectedNodeData.key,
        };
        setEditedNodeData(data);
        break;
      case NODES.VEHICLE.TYPE:
        data = {
          nodeType,
          company: { ...(selectedNodeData.company || { companyId: selectedNodeData.companyId }) },
          division: {
            ...(
              selectedNodeData.nodeType === NODES.DIVISION.TYPE ? selectedNodeData
                : (selectedNodeData.division || selectedNodeData.parent)
            ),
          },
          key: selectedNodeData.key,
        };
        setEditedNodeData(data);
        break;
      case NODES.USER.TYPE:
        data = {
          nodeType,
          company: {
            ...(
              selectedNodeData.nodeType === NODES.COMPANY.TYPE
                ? selectedNodeData
                : selectedNodeData.company
            )
          },
          key: selectedNodeData.key,
        };
        setEditedNodeData(data);
        break;
      default:
        if (nodeType) {
          setEditedNodeData({ nodeType });
        } else {
          setEditedNodeData(null);
        }
        break;
    }
  };

  const saveCompaniesIfDifferent = (newCompanies) => {
    if (newCompanies?.map((c) => c.companyId).join('#') !== companies?.map((c) => c.companyId).join('#')) {
      setCompanies(newCompanies);
    }
  };

  useEffect(() => {
    if (user?.id) {
      getAppUsers({ userId: user.id }).then((res) => {
        const companyId = res?.[0]?.company?.companyId;
        if (companyId) {
          getCompany(companyId).then((data) => {
            saveCompaniesIfDifferent([data]);
          }).catch((error) => { throw error; });
        } else {
          getCompanies().then((data) => {
            saveCompaniesIfDifferent(data.sort((a, b) => (`${a.name}`).localeCompare(b.name)));
          }).catch((error) => { throw error; });
        }
      }).catch((error) => { throw error; });
    }
  }, [user?.id]);

  const location = useLocation();
  useEffect(() => {
    const isDefaultUrl = location.pathname === '/companies';
    if (companies?.length && treeData?.length && isDefaultUrl) {
      toogleSelectedNode(['0']);
      if (isCompanyAdmin) {
        setExpandedKeys(['0']);
      }
    }
  }, [companies, user, toogleSelectedNode, treeData, location]);

  useEffect(() => {
    setIsCreateCompany(false);
  }, [selectedNodeData]);

  const companyInfoPanel = useMemo(() => [
    {
      name: 'Основная',
      path: tabsRoutes.company.main,
      component: (
        <CompanyInfoPanel
          updateTree={updateTree}
          updateTreeForCompany={updateTreeForCompany}
          selectedData={selectedNodeData}
          editedNodeData={editedNodeData}
          remove={remove}
          add={add}
          isFormChanged={isFormChanged}
          setIsFormChanged={setIsFormChanged}
          isErr={isErr}
          setIsErr={setIsErr}
        />),
    },
  ], [selectedNodeData, isFormChanged, editedNodeData, isErr]);

  const companyPassengersPanel = useMemo(() => [
    {
      name: 'Основная',
      path: tabsRoutes.passengers.main,
      component: (
        <CompanyPassengersPanel
          companyId={selectedNodeData?.company?.companyId}
          toogleSelectedNode={toogleSelectedNode}
          treeSelectedKeys={treeSelectedKeys}
        />
      ),
    },
  ], [selectedNodeData, treeSelectedKeys, toogleSelectedNode]);

  const companyCargoPanel = useMemo(() => [
    {
      name: 'Основная',
      path: tabsRoutes.cargo.main,
      component: (
        <CompanyPassengersPanel
          companyId={selectedNodeData?.company?.companyId}
          toogleSelectedNode={toogleSelectedNode}
          treeSelectedKeys={treeSelectedKeys}
          cargoType="CARGO_TYPE_CARGO"
        />
      ),
    },
  ], [selectedNodeData, treeSelectedKeys, toogleSelectedNode]);

  const divisionPanels = useMemo(() => [
    {
      name: 'Карта',
      path: tabsRoutes.division.geomap,
      component: (
        <CompanyMapPanel
          division={selectedNodeData?.divisionId ? selectedNodeData : null}
        />
      )
    },
    {
      name: 'Доступ',
      path: tabsRoutes.company.access,
      component: (
        <DivisionAccessBll
          divisionId={selectedNodeData?.divisionId}
          companyId={selectedNodeData?.company?.companyId}
        >
          <DivisionAccessPanel companyId={selectedNodeData?.companyId} />
        </DivisionAccessBll>
      ),
    },
  ], [selectedNodeData]);

  const companyPanels = useMemo(() => {
    const arr = [
      {
        name: 'Карта',
        path: tabsRoutes.company.geomap,
        component: (
          <CompanyMapPanel
            company={selectedNodeData?.companyId ? selectedNodeData : null}
          />
        )
      },
      {
        name: 'Маршруты',
        path: tabsRoutes.company.routes,
        component: (
          <CompanyRoutesPanel
            companyId={selectedNodeData?.companyId || selectedNodeData?.company?.companyId}
          />
        ),
      },
      {
        name: 'Геозоны',
        path: tabsRoutes.company.geozones,
        component: (
          <CompanyGeoZonesPanel
            company={selectedNodeData?.companyId ? selectedNodeData : selectedNodeData?.company}
          />
        ),
      },
      {
        name: 'Сообщения',
        path: tabsRoutes.company.alerts,
        component: (
          <AlertsSettingsResourceBll companyId={selectedNodeData?.companyId}>
            <CompanyAlertsPanel
              selectedData={selectedNodeData}
            />
          </AlertsSettingsResourceBll>
        ),
      },
      ...(
        (user?.currentTariffPlan?.funcGroupMvmnt || isAdmin) ? [{
          name: 'Группы ТС',
          path: tabsRoutes.company.platoons,
          component: (
            <CompanyPlatoonGroupPanel
              company={selectedNodeData}
            />
          )
        },
        {
          name: 'Логистика',
          path: tabsRoutes.company.logistics,
          component: (
            <CompanyLogisticsTablePanel company={selectedNodeData} />
          )
        }] : []
      ),
    ];

    if (isCompanyAdmin || isAdmin) {
      arr.push({
        name: 'Отчеты',
        path: tabsRoutes.company.reports,
        component: (
          <PrivateRoute
            roles={['ROLE_ADMIN', 'ROLE_COMPANY_ADMIN']}
            isEvery={false}
          >
            <CompanyReportsPanel companyId={selectedNodeData?.companyId} />
          </PrivateRoute>
        )
      });
    }

    return arr;
  }, [selectedNodeData, user?.currentTariffPlan, isCompanyAdmin, isAdmin]);

  const companyCreate = useMemo(() => [
    {
      name: 'Создание компании',
      component: (
        <CompanyFormWidget
          updateTreeForCompany={updateTreeForCompany}
          selectedCompany={{ nodeType: 'COMPANY' }}
          readOnly={!isAdmin}
          setIsFormChanged={setIsFormChanged}
          isFormChanged
        />),
    },
  ], [isFormChanged, editedNodeData, updateTreeForCompany]);

  const vehiclePanels = useMemo(() => {
    if (!selectedNodeData) {
      return null;
    }
    return ([{
      name: 'История',
      path: tabsRoutes.vehicle.history,
      component: (
        <VehicleMapPanelBll vehicleId={selectedNodeData?.vehicleId}>
          <VehicleMapPanel
            vehicle={selectedNodeData}
            vehicleId={selectedNodeData?.vehicleId}
          />
        </VehicleMapPanelBll>
      )
    }, ...(
      (user?.currentTariffPlan?.funcFuelCoef || isAdmin) ? [{
        name: 'ТЭ',
        path: tabsRoutes.vehicle.fuelefficiency,
        component: (
          <VehicleFuelEfficiencyBll>
            <VehicleFuelEfficiencyPanel id={selectedNodeData?.vehicleId} />
          </VehicleFuelEfficiencyBll>
        )
      }] : []
    ), {
      name: 'Оборудование',
      path: tabsRoutes.vehicle.equipment,
      component: (
        <EquipmentBll vehicleId={selectedNodeData?.vehicleId}>
          <EquipmentPanel
            vehicleId={selectedNodeData?.vehicleId}
          />
        </EquipmentBll>
      ),
    },
    {
      name: 'СОСТОЯНИЕ',
      path: tabsRoutes.vehicle.state,
      component: (
        <VehicleBll vehicleId={selectedNodeData?.vehicleId}>
          <StatePanel />
        </VehicleBll>
      ),
    }, ...(
      user?.currentTariffPlan?.funcGroupMvmnt ? [{
        name: 'Группа ТС',
        path: tabsRoutes.vehicle.platoon,
        component: (
          <VehicleGroupPanel
            vehicle={selectedNodeData}
            company={selectedNodeData?.company}
          />
        )
      }] : []
    ), ...(
      isCompanyAdmin ? [{
        name: 'Пробег',
        path: tabsRoutes.vehicle.mileage,
        component: (
          <VehicleReportingBll vehicle={selectedNodeData} company={companies[0]}>
            <ReportingPanel />
          </VehicleReportingBll>
        )
      }] : []
    ),
    ]);
  }, [selectedNodeData]);

  const renderTabPanel = useCallback((nodeType) => {
    switch (nodeType) {
      case NODES.COMPANY.TYPE:
        return selectedNodeData?.companyId
          ? [...companyInfoPanel, ...companyPanels]
          : companyInfoPanel;
      case NODES.VEHICLE.TYPE:
        return selectedNodeData?.vehicleId
          ? [...companyInfoPanel, ...vehiclePanels]
          : companyInfoPanel;
      case NODES.DIVISION.TYPE:
        return selectedNodeData?.divisionId
          ? [...companyInfoPanel, ...divisionPanels]
          : companyInfoPanel;
      case NODES.PASSENGERS.TYPE:
        return companyPassengersPanel;
      case NODES.CARGO.TYPE:
        return companyCargoPanel;
      default:
        return companyInfoPanel;
    }
  }, [
    selectedNodeData, companyInfoPanel, companyPanels,
    vehiclePanels, companyPassengersPanel, companyCargoPanel
  ]);

  const curTabs = useSelector((state) => state[`${reducerPath.companyTabsPanelState}/counter`]);

  const [treeWidth, setTreeWidth] = useState(minTreeWidth);
  const [compactMode, setCompactMode] = useState(false);

  const resizeTreeWidth = (e) => {
    const newWidth = e.clientX;
    if (newWidth >= minTreeWidth && newWidth <= maxTreeWidth) {
      setTreeWidth(newWidth);
    }
  };

  const onMouseDownDivider = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    window.addEventListener('mousemove', resizeTreeWidth);
    window.addEventListener('mouseup', () => {
      window.removeEventListener('mousemove', resizeTreeWidth);
    });
  };

  const openNodes = useMemo(() => (
    getOpenNodes(treeData, treeSelectedKeys[0] || '')
  ), [treeData, treeSelectedKeys]);

  return (
    <Container
      component="main"
      maxWidth="xl"
      sx={{
        position: 'relative',
        minHeight: 'calc(100vh - 64px)',
      }}
    >
      <Box
        sx={{
          height: '100%',
          position: 'absolute',
          top: '0px',
          left: `${treeWidth}px`,
          width: '3px',
          cursor: 'col-resize',
          display: compactMode ? 'none' : 'flex',
          justifyContent: 'center',
          zIndex: 200,
        }}
        onMouseDown={onMouseDownDivider}
      />
      <Box sx={{ display: 'flex', width: '100%' }}>
        {!companies
          ? <Loading />
          : !companies.length
            ? <NoData />
            : (
              <CompanyTreePanel
                treeSelectedKeys={treeSelectedKeys}
                setTreeSelectedKeys={setTreeSelectedKeys}
                setSelectedData={setSelectedNodeData}
                treeData={treeData}
                setTreeData={setTreeData}
                companies={companies}
                isFormChanged={isFormChanged}
                setIsFormChanged={setIsFormChanged}
                setIsCreateCompany={setIsCreateCompany}
                toogleSelectedNode={toogleSelectedNode}
                isAdmin={!!isAdmin}
                expandedKeys={expandedKeys}
                setExpandedKeys={setExpandedKeys}
                treeWidth={treeWidth}
                setCompactMode={setCompactMode}
                compactMode={compactMode}
              />
            )}
        <Box sx={{
          flex: '1', paddingTop: "20px", paddingLeft: compactMode ? '60px' : { xs: '60px', lg: '20px' }, height: '100%',
        }}
        >
          <Breadcrumbs maxItems={4} aria-label="breadcrumb">
            {
              treeSelectedKeys?.length && treeData?.length
                ? openNodes
                  .map((e, i, arr) => (
                    i + 1 === arr.length
                      ? (
                        <Typography
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          key={`Breadcrumbs-${e?.id}-${i + 1}`}
                          color="text.primary"
                        >
                          {e?.icon}
                          {' '}
                          {e?.title}
                        </Typography>
                      )
                      : (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer"
                          }}
                          key={`Breadcrumbs-${e.id}-${i + 1}`}
                          underline="hover"
                          color="inherit"
                          onClick={() => {
                            navigate(getUrlForNode(arr.slice(0, i + 1), curTabs));
                            setTreeSelectedKeys([e.key]);
                            setSelectedNodeData(e.data);
                          }}
                        >
                          {e.icon}
                          {' '}
                          {e.title}
                        </Link>
                      )
                  ))
                : null
            }
          </Breadcrumbs>
          {isCreateCompany ? <TabsPanels tabs={companyCreate} />
            : selectedNodeData
              ? (
                <>
                  {
                  dataDivisionsResource?.data.length === 0
                    && (
                    <Alert severity="info" sx={{ mt: 2 }}>
                      Для быстрого старта работы с системой предлагаем воспользоваться мастером
                      {' '}
                      <RouterLink to="/wizard">
                        <Typography variant="body2" component="span" sx={{ textDecoration: 'underline' }}>настройки</Typography>
                      </RouterLink>
                      .
                    </Alert>
                    )
                  }
                  <CompanyTabsPanels
                    nodeType={selectedNodeData.nodeType}
                    tabs={renderTabPanel(selectedNodeData.nodeType)}
                  />
                </>
              )
              : null}
        </Box>
      </Box>
    </Container>
  );
}

export default CompanyView;
