import PropTypes from 'prop-types';
import {
  Autocomplete,
  Avatar,
  Box,
  Checkbox,
  Collapse,
  FormControlLabel, IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, {
  useCallback,
  useEffect, useMemo, useState
} from 'react';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { red } from '@mui/material/colors';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import RoomIcon from '@mui/icons-material/Room';
import SearchIcon from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { fromLonLat, toLonLat } from "ol/proj";
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import ClearIcon from '@mui/icons-material/Clear';
import { getCompilationPoints } from '../../../shared/api/api';
import style from './PointForm.module.css';
import ActionBarDateTimePicker from '../../../shared/ui/ActionBarDateTimePicker/ActionBarDateTimePicker';
import isValidDate from '../../../shared/utils/isValidDate';
// eslint-disable-next-line boundaries/element-types
import GeoMapWithTiles from "../../geo-map/GeoMapWithTiles";
import MarkerVectorLayer from "../../../shared/ui/GeoMap/MarkerVectorLayer";
import useDebounce from "../../../shared/hooks/useDebounce";
import LineVectorLayer from "../../../shared/ui/GeoMap/LineVectorLayer";

const handleKeyDown = (event) => {
  if (event.key === "Enter") {
    event.target.blur();
  }
};

// 5 знаков после запятой
const decimalPlaces = 5;

export const typesPoint = {
  POINT: "POINT",
  POINT_GEOZONE: "POINT_GEOZONE",
  POINT_GEONAME: "POINT_GEONAME",
};

function extractCoordinates(inputString) {
  try {
    // Разделяем строку на массив координат
    const coordinatesArray = inputString.split(',');

    // Проверяем, что получено ровно две координаты
    if (coordinatesArray.length !== 2) {
      return ('Неверный формат координат. Пожалуйста, введите две координаты через запятую. Пример: "55.757869, 37.619846"');
    }

    // Преобразуем строки в числа
    const latitude = parseFloat(coordinatesArray[0]);
    const longitude = parseFloat(coordinatesArray[1]);

    // Проверяем, что удалось преобразовать в числа
    if (Number.isNaN(latitude) || Number.isNaN(longitude)) {
      return ('Неверный формат чисел в координатах. Пример: "55.757869, 37.619846"');
    }

    // Возвращаем объект с координатами
    return { latitude, longitude };
  } catch {
    return "Ошибка"; // Возвращаем null в случае ошибки
  }
}

export const arrayTypesPoint = [
  { id: 0, name: typesPoint.POINT, ruName: "Точка" },
  { id: 1, name: typesPoint.POINT_GEOZONE, ruName: "Гео зона" },
  { id: 2, name: typesPoint.POINT_GEONAME, ruName: "Гео название" },
];
// Дефолтное состояние зума карты
const zoom = 7;

// Форма заполнения инфо о точке (содержит поля ввода времени и значки-чекбоксы)
function PointForm({
  point,
  index,
  setPoints,
  buttons,
  prevPoint,
  companyId,
  rememberedDate,
  automaticTimeShift,
  points,
  isEditComment,
  onBlurComment,
  onClickComment,
}) {
  // Поле ввода для населенного пункта
  const [inputValueGeo, setInputValueGeo] = useState('');
  // Список населенных пунктов для комбобокса
  const [geoPoints, setGeoPoints] = useState([]);
  const [isShowStop, setIsShowStop] = useState(Boolean(point.stopTime || point.stopTimeUntil));
  // Состояние при котором будут отправляться запросы на сервер для поиска маршрута
  const [isFocusedAutocomplete, setIsFocusedAutocomplete] = useState(false);

  const [isShowStopBack, setIsShowStopBack] = useState(
    Boolean(point.backStopTime || point.backStopTimeUntil)
  );

  const debouncedInputValueGeo = useDebounce((value) => {
    setInputValueGeo(value);
  }, 500);

  // Изменение в чекбоксе
  const changeCheckbox = (event) => {
    setPoints((prev) => {
      const newPoints = [...prev];
      newPoints[index] = { ...newPoints[index], [event.target.id]: !prev[index][event.target.id] };
      return newPoints;
    });
  };

  const changeText = (event) => {
    setPoints((prev) => {
      const newPoints = [...prev];
      newPoints[index] = { ...newPoints[index], [event.target.id]: event.target.value };
      return newPoints;
    });
  };

  // Изменение в поле ввода даты
  // const changeTextField = (event) => {
  //   setPoints((prev) => {
  //     const newPoints = [...prev];
  //     // const index = newPoints.findIndex((p) => p.id === point.id);
  //     if (newPoints[index].errors?.[event.target.id]) {
  //       newPoints[index].errors = {
  //         ...newPoints[index].errors,
  //         [event.target.id]: false,
  //       };
  //     }
  //     newPoints[index] = { ...newPoints[index], [event.target.id]: event.target.value };
  //     return newPoints;
  //   });
  // };

  // Изменение в поле ввода даты
  const handleChangeDate = (name, newValue) => {
    setPoints((prev) => {
      const newPoints = [...prev];
      newPoints[index] = { ...newPoints[index], [name]: newValue };
      // eslint-disable-next-line no-param-reassign
      rememberedDate.current = {
        ...rememberedDate.current,
        name: `${name}-${index}`,
        newDate: newValue,
      };
      return newPoints;
    });
  };

  const focusDate = (dateName) => {
    // eslint-disable-next-line no-param-reassign
    rememberedDate.current = {
      ...rememberedDate.current,
      name: `${dateName}-${index}`,
      prevDate: point[dateName],
      newDate: null,
    };
  };

  const blurDate = () => {
    automaticTimeShift();
  };

  const handleErrorDate = (error, value) => {
    // eslint-disable-next-line no-console
    console.log(error, value);
  };

  // Загрузка населенных пунктов при вводе
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isFocusedAutocomplete) {
      return undefined;
    }
    if (inputValueGeo.length < 3) {
      setGeoPoints(point.geoPoint ? [point.geoPoint] : []);
      return undefined;
    }

    const params = {
      name: inputValueGeo,
      lat: prevPoint?.geoPoint?.latitude,
      lon: prevPoint?.geoPoint?.longitude,
    };
    getCompilationPoints(params, companyId).then((results) => {
      let newOptions = [];

      if (point.geoPoint) {
        newOptions = [point.geoPoint];
      }

      const groupKeys = {
        popularPoints: 'Популярные',
        nearGeoZone: 'Ближайшие',
        nearGeoName: 'Ближайшие',
        otherGeoPoints: 'Остальные'
      };

      let newGeoNames = [];
      Object.keys(results).forEach((key) => {
        newGeoNames = [
          ...newGeoNames,
          ...results[key].map((geo) => ({ ...geo, group: groupKeys[key] }))
        ];
      });

      if (results) {
        newOptions = [
          ...newOptions,
          ...newGeoNames,
        ];
      }

      setGeoPoints(newOptions);
    }).catch((error) => { throw error; });
  }, [inputValueGeo, point.geoPoint, prevPoint, companyId, isFocusedAutocomplete]);

  // Показ полей ввода для точки (туда или обрантно в зависимости от параметра isBack)
  // eslint-disable-next-line no-shadow
  // Для показа данных о точке
  // const coords = useMemo(() => {
  //   if (point.lat && point.lon) {
  //     return [point.lat / 10000000, point.lon / 10000000];
  //   }
  //   return null;
  // }, [point]);
  // Координаты локальные для инпутов Широта и Долгота
  const [coordsCurrent, setCoordsCurrent] = useState(
    { lat: point?.lat ? point.lat / 10000000 : null, lon: point?.lon ? point.lon / 10000000 : null }
  );

  useEffect(() => {
    setPoints((prev) => {
      const newPoints = [...prev];
      newPoints[index] = {
        ...newPoints[index],
        lat: coordsCurrent.lat * 10000000,
        lon: coordsCurrent.lon * 10000000
      };
      return newPoints;
    });
  }, [coordsCurrent]);

  const pointCurrent = (coordsCurrent?.lon && coordsCurrent?.lat)
    ? fromLonLat([coordsCurrent.lon, coordsCurrent.lat])
    : null;

  const [coordsCurrentField, setCoordsCurrentField] = useState(
    pointCurrent
      ? `${coordsCurrent.lat}, ${coordsCurrent.lon}`
      : ""
  );

  useEffect(() => {
    if (coordsCurrentField) {
      const res = extractCoordinates(coordsCurrentField);
      if (res?.latitude && res?.longitude) {
        setCoordsCurrent((prev) => (
          {
            ...prev, lon: res.longitude, lat: res.latitude
          }));
      }
    }
  }, [coordsCurrentField]);

  const errorCoordsCurrentField = useMemo(() => {
    const extractCoordinate = extractCoordinates(coordsCurrentField);
    return (coordsCurrentField
      ? extractCoordinate
        ? typeof extractCoordinate === 'string' ? extractCoordinate : null
        : "Ошибка ввода координат, формат: 'широта, долгота'"
      : null);
  }, [coordsCurrentField, extractCoordinates]);

  const setPointCurrent = useCallback((e) => {
    if (e) {
      const cords = toLonLat(e);
      if (cords?.length === 2 && decimalPlaces) {
        if (typeof Number(cords[1]) === 'number') {
          if (typeof Number(cords[0]) === 'number') {
            // setZoom(test2.zoom);
            const lon = parseFloat(Number(cords[1]).toFixed(decimalPlaces));
            const lat = parseFloat(Number(cords[0]).toFixed(decimalPlaces));
            setCoordsCurrentField(`${lon}, ${lat}`);
          }
        }
      }
    }
  }, [setCoordsCurrentField]);

  // eslint-disable-next-line consistent-return
  const arrPointsForMap = useMemo(() => {
    const pointLast = points[points.length - 1];
    const pointPrev = points[points.length - 2];
    if (pointLast && pointPrev) {
      if (point.type === typesPoint.POINT) {
        return [...points.filter((e, i) => e.lat && e.lon && i !== index)];
      }
    }
  }, [points, point]);

  // eslint-disable-next-line consistent-return
  const centerOfPrevCenter = useMemo(() => {
    const pointLast = points[points.length - 1];
    const pointPrev = points[points.length - 2];
    if (pointLast && pointPrev) {
      if (index + 1 === points.length) {
        const res = extractCoordinates(coordsCurrentField);
        if (!(res?.latitude && res.longitude)) {
          if (point.type === typesPoint.POINT) {
            return { lat: pointPrev.lat / 10000000, lon: pointPrev.lon / 10000000 };
          }
        }
      }
    }
  }, [points, point, index, extractCoordinates, coordsCurrentField]);

  const centerForMap = point.type === typesPoint.POINT
    ? pointCurrent
      || ((centerOfPrevCenter?.lat && centerOfPrevCenter?.lon)
        ? fromLonLat([centerOfPrevCenter?.lon, centerOfPrevCenter?.lat])
        : [8767763.322339358, 8610137.178088648])
    : [8767763.322339358, 8610137.178088648];
  // Показ населенного пункта, чекбоксов туда и обратно, кнопок
  // eslint-disable-next-line no-unsafe-optional-chaining
  const countCurrentPoint = String(index + 1);

  return (
    <Box className={style.mainContainer}>
      <Box className={style.pointForm}>
        <Avatar>
          <AccessTimeIcon />
        </Avatar>
        <Box className={style.pointFormContent}>
          {point.type === typesPoint.POINT ? (
            <Box className={style.wrapperInputField}>
              <Box className={style.coordinates} sx={{ flex: "1" }}>
                <TextField
                  onChange={(e) => {
                    setCoordsCurrentField(e.target.value);
                  }}
                  id="lat"
                  label="Координаты"
                  value={coordsCurrentField}
                  type="text"
                  variant="outlined"
                  size="small"
                  error={errorCoordsCurrentField}
                  helperText={errorCoordsCurrentField}
                  className={style.textFieldCoords}
                />
              </Box>
              <IconButton
                onClick={() => {
                  setPoints((prev) => {
                    const newPoints = [...prev];
                    newPoints[index] = {
                      ...newPoints[index],
                      type: typesPoint.POINT_GEONAME,
                      lat: 0,
                      lon: 0,
                      longitude: 0,
                      latitude: 0
                    };
                    return newPoints;
                  });
                  setCoordsCurrent({ lat: null, lon: null });
                }}
                aria-label="delete"
                size="medium"
              >
                <SearchIcon
                  color="primary"
                />
              </IconButton>
            </Box>
          ) : (
            <Box className={style.wrapperInputField}>
              <Autocomplete
                fullWidth
                size="small"
                autoComplete
                includeInputInList
                groupBy={(option) => option.group}
                noOptionsText={inputValueGeo.length <= 3
                  ? 'Введите название'
                  : !geoPoints
                    ? "загрузка..."
                    : 'Нет результатов'}
                filterSelectedOptions
                value={point.type === typesPoint.POINT_GEONAME ? point.geoPoint : point.geoZone}
                onFocus={() => setIsFocusedAutocomplete(true)}
                onChange={(event, newValue) => {
                  setPoints((prev) => {
                    const newPoints = [...prev];
                    if (newValue?.type === "ZONES") {
                      newPoints[index] = {
                        ...newPoints[index],
                        type: typesPoint.POINT_GEOZONE,
                        geoZone: { ...newValue, geozoneId: newValue.id },
                        geoName: null,
                        geoPoint: {}
                      };
                    } else {
                      newPoints[index] = {
                        ...newPoints[index],
                        type: typesPoint.POINT_GEONAME,
                        geoName: newValue,
                        geoPoint: newValue,
                        geoZone: null
                      };
                    }
                    return newPoints;
                  });
                  setGeoPoints(newValue ? [newValue, ...geoPoints] : geoPoints);
                }}
                onInputChange={(event, newInputValue) => {
                  if (isFocusedAutocomplete) {
                    if (newInputValue.length >= 3) {
                      setGeoPoints(null);
                    }
                    debouncedInputValueGeo(newInputValue);
                  }
                }}
                id="geoPoint"
                options={geoPoints || []}
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.name) || ""}
                filterOptions={(x) => x}
                renderInput={(params) => <TextField {...params} label="Населенный пункт или геозона" />}
              />
              <Tooltip title="Выбор точки на карте">
                <IconButton
                  onClick={() => {
                    setPoints((prev) => {
                      const newPoints = [...prev];
                      newPoints[index] = {
                        ...newPoints[index],
                        type: typesPoint.POINT,
                        geoZone: null,
                        geoName: null,
                        geoPoint: {}
                      };
                      return newPoints;
                    });
                  }}
                  aria-label="delete"
                  size="medium"
                >
                  <AddLocationAltIcon
                    color="primary"
                  />
                </IconButton>
              </Tooltip>
            </Box>
          )}
          {point?.comment || isEditComment
            ? (
              <Box className={style.commnet}>
                {point?.comment && !isEditComment
                  ? (
                    <Typography variant="body2" gutterBottom onClick={onClickComment}>
                      {point?.comment}
                    </Typography>
                  )
                  : null}
                {isEditComment && (
                <TextField
                  label="Комментарий"
                  variant="standard"
                  fullWidth
                  value={point.comment}
                  onChange={changeText}
                  autoFocus
                  id="comment"
                  onBlur={onBlurComment}
                  InputProps={{
                    endAdornment: point.comment ? (
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => {
                            setPoints((prev) => {
                              const newPoints = [...prev];
                              newPoints[index] = { ...newPoints[index], comment: "" };
                              return newPoints;
                            });
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </InputAdornment>
                    ) : undefined,
                  }}
                />
                )}
              </Box>
            )
            : null}
          <FormControlLabel
            sx={{ pt: 1 }}
            control={(
              <Checkbox
                id="isSkip"
                checked={!point.isSkip}
                color="primary"
                onChange={changeCheckbox}
              />
                )}
            label="Туда"
          />
          <Collapse in={!point.isSkip}>
            <RenderPointInputs
              isBack={false}
              point={point}
              changeCheckbox={changeCheckbox}
              handleErrorDate={handleErrorDate}
              handleChangeDate={handleChangeDate}
              focusDate={focusDate}
              blurDate={blurDate}
              isShowStopBack={isShowStopBack}
              isShowStop={isShowStop}
              setPoints={setPoints}
              setIsShowStopBack={setIsShowStopBack}
              index={index}
              setIsShowStop={setIsShowStop}
            />
          </Collapse>
          <FormControlLabel
            sx={{ display: 'flex' }}
            control={(
              <Checkbox
                id="isBackSkip"
                checked={!point.isBackSkip}
                color="primary"
                onChange={changeCheckbox}
              />
                )}
            label="Обратно"
          />
          <Collapse in={!point.isBackSkip}>
            <RenderPointInputs
              isBack
              point={point}
              changeCheckbox={changeCheckbox}
              handleErrorDate={handleErrorDate}
              handleChangeDate={handleChangeDate}
              focusDate={focusDate}
              blurDate={blurDate}
              isShowStopBack={isShowStopBack}
              isShowStop={isShowStop}
              setPoints={setPoints}
              setIsShowStopBack={setIsShowStopBack}
              index={index}
              setIsShowStop={setIsShowStop}
            />
          </Collapse>
        </Box>
        <Box sx={{ display: 'flex' }}>
          {buttons}
        </Box>
      </Box>
      {point.type === typesPoint.POINT && (
        <Box className={style.pointForm}>
          {point.type === typesPoint.POINT ? (
            <Avatar>
              <RoomIcon />
            </Avatar>
          ) : null }
          {(point.type !== typesPoint.POINT_GEONAME && point.type !== typesPoint.POINT_GEONAME) && (
          <Box sx={{ marginTop: "-20px" }} className={style.pointFormContentForMap}>
            <Box>
              <GeoMapWithTiles
                styles={style.geoMap}
                center={centerForMap}
                zoom={point.type === typesPoint.POINT
                  ? pointCurrent
                    ? coordsCurrentField.length ? Infinity
                      : zoom
                    : (centerOfPrevCenter?.lat && centerOfPrevCenter?.lon)
                      ? Infinity : 3
                  : 3}
                isZoomFocused={!!coordsCurrentField}
                isOffButtonFollowMode
                disableFullScreen
                setPointCurrent={setPointCurrent}
                isSniperMode
              >
                {point.type === typesPoint.POINT ? pointCurrent ? (
                  <MarkerVectorLayer
                    coordinates={pointCurrent}
                    text={countCurrentPoint}
                    isCurrentPoint
                  />
                ) : null : null }
                {arrPointsForMap?.map((pointMarker, i) => {
                  const numberPosition = index <= i ? i + 2 : i + 1;
                  return (
                    <MarkerVectorLayer
                      zIndex={9}
                      key={`point-number-${i + numberPosition}`}
                      coordinates={fromLonLat(
                        [pointMarker.lon / 10000000, pointMarker.lat / 10000000]
                      )}
                      point
                      numerary
                      text={`${numberPosition}`}
                    />
                  );
                })}
                {arrPointsForMap?.length ? (
                  <LineVectorLayer
                    coordinates={arrPointsForMap.length
                      ? points?.reduce((acc, vehicleRouteTrack) => {
                        if (vehicleRouteTrack.lat !== 0 && vehicleRouteTrack.lon !== 0) {
                          acc.push(fromLonLat(
                            [vehicleRouteTrack.lon / 10000000, vehicleRouteTrack.lat / 10000000]
                          ));
                        }
                        return acc;
                      }, [])
                      : null}
                  />
                ) : null}
              </GeoMapWithTiles>
            </Box>
          </Box>
          )}

        </Box>
      )}
    </Box>
  );
}

function RenderPointInputs({
  isBack,
  point,
  changeCheckbox,
  handleErrorDate,
  handleChangeDate,
  focusDate,
  blurDate,
  isShowStopBack,
  isShowStop,
  setPoints,
  setIsShowStopBack,
  index,
  setIsShowStop
}) {
  const arriveTime = isBack ? 'backArriveTime' : 'arriveTime';
  const arriveTimeUntil = isBack ? 'backArriveTimeUntil' : 'arriveTimeUntil';
  const leaveTime = isBack ? 'backLeaveTime' : 'leaveTime';
  const leaveTimeUntil = isBack ? 'backLeaveTimeUntil' : 'leaveTimeUntil';
  const isArriveAlert = isBack ? 'isBackArriveAlert' : 'isArriveAlert';
  const isArriveInform = isBack ? 'isBackArriveInform' : 'isArriveInform';
  const isLeaveAlert = isBack ? 'isBackLeaveAlert' : 'isLeaveAlert';
  const isLeaveInform = isBack ? 'isBackLeaveInform' : 'isLeaveInform';
  const stopTime = isBack ? 'backStopTime' : 'stopTime';
  const stopTimeUntil = isBack ? 'backStopTimeUntil' : 'stopTimeUntil';

  return (
    <Box className={style.inputsPoint}>
      <Box className={style.inputsGroup}>
        <Box className={style.checkboxGroup}>
          <Tooltip title="Информировать о прибытии">
            <Checkbox
              icon={<ErrorOutlineOutlinedIcon className={style.informIcon} />}
              checkedIcon={<ErrorOutlineOutlinedIcon className={style.informIcon} />}
              id={isArriveInform}
              checked={point[isArriveInform]}
              onChange={changeCheckbox}
            />
          </Tooltip>
          <Tooltip title="Уведомлять об опоздании">
            <Checkbox
              icon={<WarningAmberOutlinedIcon />}
              checkedIcon={<WarningAmberOutlinedIcon sx={{ color: red[500] }} />}
              id={isArriveAlert}
              checked={point[isArriveAlert]}
              onChange={changeCheckbox}
            />
          </Tooltip>
        </Box>
        <DateTimePicker
          label="C"
          value={point[arriveTime] || null}
          onError={(error, value) => handleErrorDate(error, value)}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(arriveTime, date);
          }}
          onOpen={() => focusDate(arriveTime)}
          onAccept={() => blurDate(arriveTime)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              onKeyDown={handleKeyDown}
              onFocus={() => focusDate(arriveTime)}
              onBlur={() => blurDate(arriveTime)}
              {...params}
            />
          )}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
        />
        <DateTimePicker
          label="По"
          value={point[arriveTimeUntil]}
          minDateTime={point[arriveTime]}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(arriveTimeUntil, date);
          }}
          onError={(error, value) => handleErrorDate(error, value)}
          onOpen={() => focusDate(arriveTimeUntil)}
          onAccept={() => blurDate(arriveTimeUntil)}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              id={arriveTimeUntil}
              onKeyDown={handleKeyDown}
              onFocus={() => focusDate(arriveTimeUntil)}
              onBlur={() => blurDate(arriveTimeUntil)}
              {...params}
            />
          )}
        />
      </Box>
      <Box className={style.inputsGroup}>
        <Box className={style.checkboxGroup}>
          <Tooltip title="Время стоянки">
            <Checkbox
              icon={<LocalParkingIcon />}
              checkedIcon={<LocalParkingIcon />}
              id={isBack ? 'isShowStopBack' : 'isShowStop'}
              checked={isBack ? isShowStopBack : isShowStop}
              onChange={(e) => {
                if (!e.target.checked) {
                  setPoints((prev) => {
                    const newPoints = [...prev];
                    newPoints[index] = {
                      ...newPoints[index],
                      [stopTime]: null,
                      [stopTimeUntil]: null,
                    };
                    return newPoints;
                  });
                }
                if (isBack) {
                  setIsShowStopBack(e.target.checked);
                } else {
                  setIsShowStop(e.target.checked);
                }
              }}
            />
          </Tooltip>
        </Box>
        <DateTimePicker
          label="C"
          value={point[stopTime]}
          disabled={!(isBack ? isShowStopBack : isShowStop)}
          onError={(error, value) => handleErrorDate(error, value)}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(stopTime, date);
          }}
          onOpen={() => focusDate(stopTime)}
          onAccept={() => blurDate(stopTime)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              {...params}
              onFocus={() => focusDate(stopTime)}
              onBlur={() => blurDate(stopTime)}
            />
          )}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
        />
        <DateTimePicker
          disabled={!(isBack ? isShowStopBack : isShowStop)}
          label="По"
          value={point[stopTimeUntil]}
          minDateTime={point[stopTime]}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(stopTimeUntil, date);
          }}
          onError={(error, value) => handleErrorDate(error, value)}
          onOpen={() => focusDate(stopTimeUntil)}
          onAccept={() => blurDate(stopTimeUntil)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              {...params}
              onFocus={() => focusDate(stopTimeUntil)}
              onBlur={() => blurDate(stopTimeUntil)}
            />
          )}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
        />
      </Box>
      <Box className={style.inputsGroup}>
        <Box className={style.checkboxGroup}>
          <Tooltip title="Информировать об отправлении">
            <Checkbox
              icon={<ErrorOutlineOutlinedIcon className={style.informIcon} />}
              checkedIcon={<ErrorOutlineOutlinedIcon className={style.informIcon} />}
              id={isLeaveInform}
              checked={point[isLeaveInform]}
              onChange={changeCheckbox}
            />
          </Tooltip>
          <Tooltip title="Уведмолять об опоздании">
            <Checkbox
              icon={<WarningAmberOutlinedIcon />}
              checkedIcon={<WarningAmberOutlinedIcon sx={{ color: red[500] }} />}
              id={isLeaveAlert}
              checked={point[isLeaveAlert]}
              onChange={changeCheckbox}
            />
          </Tooltip>
        </Box>
        <DateTimePicker
          label="C"
          value={point[leaveTime]}
          onError={(error, value) => handleErrorDate(error, value)}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(leaveTime, date);
          }}
          onOpen={() => focusDate(leaveTime)}
          onAccept={() => blurDate(leaveTime)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              {...params}
              onFocus={() => focusDate(leaveTime)}
              onBlur={() => blurDate(leaveTime)}
            />
          )}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
        />
        <DateTimePicker
          label="По"
          value={point[leaveTimeUntil]}
          minDateTime={point[leaveTime]}
          onChange={(newDate) => {
            const date = isValidDate(newDate) ? new Date(newDate.setSeconds(0)) : newDate;
            handleChangeDate(leaveTimeUntil, date);
          }}
          onError={(error, value) => handleErrorDate(error, value)}
          onOpen={() => focusDate(leaveTimeUntil)}
          onAccept={() => blurDate(leaveTimeUntil)}
          renderInput={(params) => (
            <TextField
              variant="standard"
              size="small"
              fullWidth
              {...params}
              onFocus={() => focusDate(leaveTimeUntil)}
              onBlur={() => blurDate(leaveTimeUntil)}
            />
          )}
          components={{
            ActionBar: ActionBarDateTimePicker,
          }}
        />
      </Box>
    </Box>
  );
}

RenderPointInputs.propTypes = {
  isBack: PropTypes.bool,
  point: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  changeCheckbox: PropTypes.func.isRequired,
  handleErrorDate: PropTypes.func.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  focusDate: PropTypes.func.isRequired,
  blurDate: PropTypes.func.isRequired,
  isShowStopBack: PropTypes.bool,
  isShowStop: PropTypes.bool,
  setPoints: PropTypes.func.isRequired,
  setIsShowStopBack: PropTypes.func.isRequired,
  index: PropTypes.number,
  setIsShowStop: PropTypes.func.isRequired
};

RenderPointInputs.defaultProps = {
  isBack: null,
  isShowStopBack: null,
  isShowStop: null,
  index: 0
};

PointForm.propTypes = {
  prevPoint: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])),
  index: PropTypes.number.isRequired,
  point: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  points: PropTypes.array.isRequired,
  setPoints: PropTypes.func.isRequired,
  buttons: PropTypes.element.isRequired,
  backPointInputs: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  pointInputs: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
  ])).isRequired,
  pointsCount: PropTypes.number,
  rememberedDate: PropTypes.shape({
    current: PropTypes.shape({
      name: PropTypes.string,
      prevDate: PropTypes.instanceOf(Date),
      newDate: PropTypes.instanceOf(Date),
      pointIndex: PropTypes.number,
    })
  }).isRequired,
  automaticTimeShift: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  isEditComment: PropTypes.bool.isRequired,
  onBlurComment: PropTypes.func.isRequired,
  onClickComment: PropTypes.func.isRequired,
};

PointForm.defaultProps = {
  prevPoint: null,
  pointsCount: 0,
};

export default PointForm;
