import {
  Alert, AlertTitle,
  Box, Button, TextField
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import style from './CompanyFormWidget.module.css';
import { patchCompany, postCompany } from '../../shared/api/api';

const initialCompany = {
  name: '',
  site: '',
  address: '',
};

// Отображает форму редактирования с инфо о компании
function CompanyFormWidget(props) {
  const {
    selectedCompany, readOnly, updateTreeForCompany, setIsFormChanged, isFormChanged,
    isErr, setIsErr
  } = props;

  const [company, setCompany] = useState({ ...initialCompany, ...selectedCompany });
  const [errors, setErrors] = useState({ ...initialCompany });

  const [isCorrect, setIsCorrect] = useState(false);

  const changeTextField = (event) => {
    if (setIsErr) setIsErr(false);
    setIsCorrect(false);
    setCompany((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    if (errors[event.target.id]) {
      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
    }
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  const resetForm = useCallback((companyInfo) => {
    setIsFormChanged(false);
    setErrors({ ...initialCompany });
    setCompany({ ...initialCompany, ...companyInfo });
  }, [setIsFormChanged]);

  const confirm = useConfirm();
  const handleResetForm = (companyInfo) => {
    confirm({
      title: 'Отменить изменения?',
      confirmationText: 'Да',
      cancellationText: 'Нет',
      description: `Вы уверены, что хотите отменить изменения в форме?`
    })
      .then(() => {
        resetForm(companyInfo);
      });
  };

  const saveForm = useCallback(() => {
    let isError = false;
    Object.keys(initialCompany).forEach((key) => {
      if (!isError) {
        isError = !(company[key]);
      }
      setErrors((prev) => ({ ...prev, [key]: !(company[key]) }));
    });
    if (!isError) {
      const body = {
        name: company.name,
        site: company.site,
        address: company.address,
      };

      if (selectedCompany.companyId) {
        patchCompany({ ...body, companyId: selectedCompany.companyId }).then((res) => {
          updateTreeForCompany(selectedCompany.key, company);
          resetForm(res);
          if (res.error) {
            setIsErr(true);
          } else {
            setIsCorrect(true);
          }
        }).catch((error) => { throw error; });
      } else {
        postCompany(body).then((res) => {
          updateTreeForCompany(selectedCompany.key, { ...res, ...company }, true);
          resetForm(res);
          if (res.error) {
            setIsErr(true);
          } else {
            setIsCorrect(true);
          }
        }).catch((error) => { throw error; });
      }
    }
  }, [company, resetForm, updateTreeForCompany, selectedCompany]);

  useEffect(() => {
    resetForm(selectedCompany);
  }, [selectedCompany, resetForm]);

  return (
    <Box className={style.companyFormWidget}>
      <TextField
        fullWidth
        id="name"
        label="Наименование"
        value={company.name}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.name)}
        helperText={errors.name ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <TextField
        fullWidth
        id="site"
        label="Сайт"
        value={company.site}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.site)}
        helperText={errors.site ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <TextField
        fullWidth
        id="address"
        label="Адрес"
        value={company.address}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.address)}
        helperText={errors.address ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      {!isErr && isCorrect
          && (
          <Alert sx={{ width: "100%" }} severity="success">
            <AlertTitle>Успешно</AlertTitle>
            Данные успешно сохранены
          </Alert>
          )}
      {isErr
          && (
          <Alert sx={{ width: "100%" }} severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            Произошла ошибка
          </Alert>
          )}
      {readOnly
        ? null
        : (
          <div className={style.buttonts}>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => handleResetForm(selectedCompany)}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disableElevation
              disabled={!isFormChanged}
              onClick={() => saveForm()}
              variant="contained"
            >
              Сохранить
            </Button>
          </div>
        )}
    </Box>
  );
}

CompanyFormWidget.propTypes = {
  selectedCompany: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  readOnly: PropTypes.bool,
  updateTreeForCompany: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
  isErr: PropTypes.bool,
  setIsErr: PropTypes.func,
};

CompanyFormWidget.defaultProps = {
  selectedCompany: null,
  readOnly: true,
  isErr: null,
  setIsErr: null,
};

export default CompanyFormWidget;
