import { createApi } from '@reduxjs/toolkit/query/react';
import reducerPath from "../../../../app/reducerPath";
import createHeadRequest from "../../../../shared/http/createHeadRequest";
import checkStatus from "../../../../shared/http/checkStatus";
import HttpMethods from "../../../../shared/api/HttpMethods";
import sensorsValuesTypesResourceFromDtoService from "../services/data/sensorsValuesTypesResource-from-dto.service";
// eslint-disable-next-line boundaries/element-types
import baseQueryWithReAuth from "../../../baseQueryWithReAuth/baseQueryWithReAuth";
import config from '../../../../shared/api/config';

// Описываем методы обращения к беку
export const sensorsValuesTypesResourceApi = createApi({
  reducerPath: `${reducerPath.sensorsValuesTypes}/api`,
  baseQuery: (...arg) => baseQueryWithReAuth(...arg, `${config.baseUrl}/sensor-value-types`),
  endpoints: (builder) => ({
    getSensorsValuesTypes: builder.query({
      query: ({
        params
      }) => ({
        ...createHeadRequest({
          url: ``, method: HttpMethods.GET
        }),
        validateStatus: checkStatus,
        params
      }),
      transformResponse: sensorsValuesTypesResourceFromDtoService
    }),
  }),
});

export const {
  useGetSensorsValuesTypesQuery,
} = sensorsValuesTypesResourceApi;
