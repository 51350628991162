import React from "react";
import { bool, func, element } from "prop-types";
import FormDialog from "../../../../../shared/ui/FormDialog/FormDialog";

const defaultPropDialog = {
  disableTextField: true,
  hideButtons: true,
  title: "Выгрузка данных датчиков для компании",
};

function ExportDataBySensorsWindow({ isOpen, onClose, children }) {
  return (
    <FormDialog
      {...defaultPropDialog}
      open={isOpen}
      handleClose={onClose}
      dialogContent={children}
    />
  );
}

ExportDataBySensorsWindow.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  children: element.isRequired,
};

export default React.memo(ExportDataBySensorsWindow);
