import { Box, CircularProgress } from "@mui/material";
import * as React from "react";
import st from "./style.module.css";

function Loader() {
  return (
    <>
      <div className={st.overlay} />

      <Box className={st.loader}>
        <CircularProgress
          size={24}
        />
      </Box>
    </>
  );
}

export default Loader;
