// eslint-disable-next-line import/no-import-module-exports
import {
  fetchBaseQuery,
} from "@reduxjs/toolkit/dist/query/react";
import reducerPath from "../../app/reducerPath";
// eslint-disable-next-line import/no-unresolved,boundaries/element-types
import { AUTH_FIELDS, clearStorage } from "../auth/tokenResource/redux/tokenResource";
import config from "../../shared/api/config";
import handleError from "../../shared/api/handleError";
import HttpMethods from "../../shared/api/HttpMethods";

const COMMON_FIELDS = {
  ERROR: "error",
  STATUS: "status",
  DATA: "data",
  TYPE: "type"
};

const HTTP_STATUSES = {
  UNAUTHORIZED: 401
};

const getTokenFromApi = (api) => {
  const state = api.getState();
  if (state && typeof state === "object") {
    if (state?.[`${reducerPath.tokenResource}/counter`]?.[AUTH_FIELDS.TOKEN_ACCESS]) {
      return state[`${reducerPath.tokenResource}/counter`][AUTH_FIELDS.TOKEN_ACCESS];
    }
  }
  // eslint-disable-next-line no-undef,implicit-arrow-linebreak
  return window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
};
const baseQueryFn = async (args, api, extraOptions, baseUrl) => {
  const baseQuery = fetchBaseQuery({
    baseUrl: `${baseUrl}`,
    prepareHeaders: (headers) => {
      const token = getTokenFromApi(api);
      if (token) headers.set("Authorization", `Bearer ${token}`);
      headers.set("Access-Control-Allow-Origin", "*");
      headers.set("Accept", "application/json");
      headers.set(
        "Access-Control-Allow-Headers",
        "Origin, X-Requested-With, Content-Type, Accept"
      );
      headers.set(
        "Access-Control-Allow-Methods",
        "DELETE, PUT, UPDATE, HEAD, OPTIONS, GET, POST"
      );
    },
  });

  return baseQuery(args, api, extraOptions);
};

export const vkRedirectGetMyTokenPage = (prevUrl, isRegistration) => {
  function generateRandomString() {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    let result = '';
    const charactersLength = characters.length;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < 32; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  }

  const uuid = generateRandomString();
  const appId = config.REACT_APP_VK_APP_ID;
  const redirectUri = `https://vm.lab.vistar.su/vk${isRegistration ? "/registration" : ""}`;
  const query = `uuid=${uuid}&app_id=${appId}&response_type=silent_token&redirect_uri=${redirectUri}&state=${prevUrl}`;
  // eslint-disable-next-line no-restricted-globals,no-undef
  location.assign(`https://id.vk.com/auth?${query}`);
};

// Функция для выполнения запроса на обновление токена
export const refreshTokenModule = async () => {
  // eslint-disable-next-line no-undef
  if (sessionStorage.getItem("isAuthVk") || localStorage.getItem("isAuthVk")) {
    clearStorage();
    // eslint-disable-next-line no-undef
    vkRedirectGetMyTokenPage(window.location.href);
  }
  return null;
};

const baseQueryWithReAuth = async (args, api, extraOptions, baseUrl) => {
  const isToastUI = (args?.body || args?.params)?.isToastUI;
  const body = args?.body;
  delete body?.isToastUI;
  const params = { ...args?.params };
  delete params?.isToastUI;
  let newArgs = args;
  if (args?.method === HttpMethods.GET) {
    newArgs = {
      ...newArgs,
      params,
    };
  } else {
    newArgs = {
      ...newArgs,
      body,
    };
  }
  const result = await baseQueryFn(newArgs, api, extraOptions, baseUrl);
  if (result?.meta?.response?.status === HTTP_STATUSES.UNAUTHORIZED) {
    await refreshTokenModule();
  }

  if (!result?.meta?.response?.ok) {
    return {
      [COMMON_FIELDS.ERROR]: {
        [COMMON_FIELDS.DATA]: handleError(result, { isRTK: true, isToastUI }),
      },
    };
  }

  return result;
};

export default baseQueryWithReAuth;
